<template>
  <div class="border rounded grid border-gray-border">
    <div class="p-3">
      <div class="border-b flex pb-3 border-gray-border items-center">
        <base-icon>
          <icon-card-confrim />
        </base-icon>
        <p>{{ $t('my_plan') }}</p>
        <div v-if="!haveCurrentPlan" class="text-xs ml-2">
          <span class="tag tag-plan__disable">{{ $t('not_use') }}</span>
        </div>
        <base-dropdown
          v-if="
            haveCurrentPlan &&
              !hasNextPlan &&
              !isFreePlan &&
              myOrder.status != 2
          "
          :max-width="150"
          position="bottom-right"
          class="cursor-pointer ml-auto"
        >
          <base-icon slot="toggle" size="14">
            <icon-dots-horizontal />
          </base-icon>
          <base-dropdown-item class="text-sm" @click="cancelPlan(myOrder)">
            {{ $t('cancel_plan') }}
          </base-dropdown-item>
        </base-dropdown>
      </div>

      <div v-if="haveCurrentPlan && getCurrentPlan.payment">
        <!-- one plan -->
        <div v-if="!hasNextPlan" class="grid" style="margin-top:14px">
          <div class="flex mb-2 font-medium items-center">
            {{ getNamePlan(myOrder) }}
            <span v-if="myOrder.status == 2" class="ml-2 tag tag-plan__next">
              {{ $t('cancel_use') }}
            </span>
          </div>
          <bar-progress
            :value="diff"
            :maximum="myOrder.numberOfDays"
            rounded
            :color="'#168AE2'"
            class="mb-2"
          />
          <div class="text-sm" style="margin-bottom: 15px">
            <p style="margin-bottom: 3px">{{ $t('remaining') }}</p>
            <p>
              {{ $tc('diff_date', diff) }}, {{ $t('valid_until') }}
              {{ formatDue(getCurrentPlan.payment.endAt) }}
            </p>
          </div>
          <div class="text-sm">
            <p style="margin-bottom: 3px">{{ $t('next_bill') }}</p>
            <p v-if="!isFreePlan && myOrder.status != 2">
              {{ formatDue(getCurrentPlan.payment.nextPaymentAt) }}
            </p>
            <p v-else>-</p>
          </div>
          <base-button
            color="white"
            icon="ChangePlan"
            responsive
            icon-size="20"
            class="py-2 border-brand-blue text-brand-blue mt-4"
            style="width: fit-content"
            @click="changePlan('change')"
            >{{ $t('change_plan') }}</base-button
          >
        </div>
        <!-- two plan -->
        <div v-else class="grid gap-y-2 mt-2">
          <div class="border p-2 rounded-md border-gray-border">
            <div class="grid">
              <div class="flex mb-2 font-medium items-center">
                {{ getNamePlan(getCurrentPlan.payment.subscriptionOrder) }}
                <span class="ml-2 tag tag-plan__active">{{ $t('use') }}</span>
              </div>
              <div class="grid-cols-3	grid  text-sm">
                <div class="border-r mr-2 border-gray-border">
                  <p>{{ $t('next_bill') }}</p>
                  <p v-if="!isFreePlan" class="font-medium">
                    {{ formatDue(getCurrentPlan.payment.nextPaymentAt) }}
                  </p>
                  <p v-else>-</p>
                </div>
                <div class="border-r mr-2 border-gray-border">
                  <p>{{ $t('start_end_at') }}</p>
                  <p class="font-medium">
                    {{
                      formatShowDate(
                        getCurrentPlan.payment.startAt,
                        getCurrentPlan.payment.endAt
                      )
                    }}
                  </p>
                </div>
                <div>
                  <p>{{ $t('remaining') }}</p>
                  <p class="font-medium">
                    {{ $tc('diff_date', diff) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="border p-2 rounded-md border-gray-border">
            <div class="grid">
              <div class="flex mb-2 font-medium items-center">
                {{ getNamePlan(orderNextPayment) }}

                <span class="tag tag-plan__next ml-2">{{
                  $t('next_plan')
                }}</span>
                <span
                  v-if="orderNextPayment.status == 2"
                  class="ml-2 tag tag-plan__next"
                  >{{ $t('cancel_use') }}</span
                >
                <base-dropdown
                  v-if="orderNextPayment.status != 2"
                  :max-width="150"
                  position="bottom-right"
                  class="cursor-pointer ml-auto"
                >
                  <base-icon slot="toggle" size="14">
                    <icon-dots-horizontal />
                  </base-icon>

                  <base-dropdown-item
                    class="text-sm"
                    @click="cancelPlan(orderNextPayment)"
                  >
                    {{ $t('cancel_plan') }}
                  </base-dropdown-item>
                </base-dropdown>
              </div>
              <div class="grid-cols-3	grid text-sm">
                <div class="border-r mr-2">
                  <p>{{ $t('next_bill') }}</p>
                  <p v-if="orderNextPayment.status != 2" class="font-medium">
                    {{
                      formatDue(
                        getCurrentPlan.nextPaymentPlans[0].nextPaymentAt
                      )
                    }}
                  </p>
                  <p v-else>-</p>
                </div>
                <div class="border-r mr-2 border-gray-border">
                  <p>{{ $t('start_end_at') }}</p>
                  <p class="font-medium">
                    {{
                      formatShowDate(
                        getCurrentPlan.nextPaymentPlans[0].startAt,
                        getCurrentPlan.nextPaymentPlans[0].endAt
                      )
                    }}
                  </p>
                </div>
                <div>
                  <p>{{ $t('remaining') }}</p>
                  <p class="font-medium">
                    {{ $tc('diff_date', orderNextPayment.numberOfDays) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <base-button
            color="white"
            icon="ChangePlan"
            responsive
            icon-size="20"
            class="py-2 border-brand-blue text-brand-blue"
            style="width: fit-content"
            @click="changePlan('change')"
            >{{ $t('change_plan') }}</base-button
          >
        </div>
      </div>
      <div v-else class="mx-12 grid justify-items-center mt-16 text-center">
        <p class="mb-2">{{ $t('not_found') }}</p>
        <div
          class="text-gray-caption text-sm"
          v-html="$t('not_found_description')"
        />
        <base-button
          class="px-8"
          style="width: fit-content;margin-top:18px"
          @click="changePlan('upgrade')"
          >{{ $t('select_plan') }}</base-button
        >
      </div>
    </div>
    <!--  <div
      v-if="haveCurrentPlan"
      class="border-t border-gray-border p-3 text-sm text-gray-caption mt-auto"
    >
      <p class="cursor-pointer hover:text-red-500" @click="cancelPlan">
        {{ $t('cancel_plan') }}
      </p>
    </div> -->
    <modalCancelPlan />
    <modal
      v-if="showModalChangePlan"
      ref="change-plan"
      name="change-plan"
      :width="widthModalChangePlan"
      :height="657"
      adaptive
      scrollable
      @closed="showModalChangePlan = false"
    >
      <subscription-plan-card
        :activity="activityPlan"
        class="overflow-y-auto h-full"
        scrollable
        @close="$modal.hide('change-plan')"
    /></modal>
  </div>
</template>
<script>
import IconCardConfrim from '@/components/icons/IconCardConfrim';
import barProgress from '@/components/bar-progress';
import modalCancelPlan from '@/components/modal/modal-cancel-plan';
import subscriptionPlanCard from '@/components/subscription/subscriptionPlanCard';
import { mapGetters } from 'vuex';
import moment from 'moment';
import FormatStartEndDate from '@/mixins/FormatStartEndDate.ts';
import { PlanStatus } from '@/types/Subscription.ts';
import IconDotsHorizontal from '@/components/icons/IconDotsHorizontal';

export default {
  mixins: [FormatStartEndDate],
  components: {
    IconCardConfrim,
    barProgress,
    modalCancelPlan,
    subscriptionPlanCard,
    IconDotsHorizontal
  },
  data() {
    return {
      activityPlan: '',
      showModalChangePlan: false
    };
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
    planeStatus() {
      return this.getCurrentPlan.payment?.subscriptionOrder?.status;
    },
    haveCurrentPlan() {
      const currentPlan =
        this.getCurrentPlan.payment?.status == PlanStatus.Active;

      return currentPlan;
    },
    haveNextPlan() {
      return this.getCurrentPlan?.nextPaymentPlans?.length > 0;
    },
    diff() {
      const setTimeOfDayToZero = {
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      };
      const today = moment(new Date()).set(setTimeOfDayToZero);
      const dateEnd = moment(this.getCurrentPlan.payment.endAt).set(
        setTimeOfDayToZero
      );
      const diffFromNowInADay = dateEnd.diff(today, 'days');

      return diffFromNowInADay;
    },
    hasNextPlan() {
      return this.getCurrentPlan.nextPaymentPlans.length > 0 ? true : false;
    },
    subscriptionOrder() {
      return this.getCurrentPlan.payment?.subscriptionOrder;
    },
    widthModalChangePlan() {
      return this.subscriptionOrder?.planID === 1 &&
        this.activityPlan === 'change'
        ? 1430
        : 1095;
    },
    isFreePlan() {
      return this.subscriptionOrder?.planID == 1;
    },
    myOrder() {
      return this.getCurrentPlan.payment.subscriptionOrder;
    },
    orderNextPayment() {
      return this.getCurrentPlan.nextPaymentPlans[0].subscriptionOrder;
    }
  },
  methods: {
    getNamePlan(plan) {
      const namePlan = [
        'freeHeader',
        'monthlyHeader',
        'sixMonthHeader',
        'annualHeader'
      ];
      return this.$t(
        `subscription.upgradeWorkspace.plan.${namePlan[plan.planID - 1]}`
      );
    },
    formatDue(date) {
      return moment(date)
        .locale(`${this.$t('notifications.language')}`)
        .format('DD MMM YYYY');
    },
    cancelPlan(order) {
      this.$store.commit('subscription/SET_PLAN_CANCEL', order);
      this.$modal.show('cancel-plan');
    },
    async changePlan(activity) {
      await this.$store.dispatch('subscription/fetchCurrentPlan');
      this.activityPlan = activity;
      this.showModalChangePlan = true;

      setTimeout(() => {
        this.$modal.show('change-plan');
      }, 200);
    }
  }
};
</script>

<style scope>
.tag-plan__active {
  @apply border text-green-400 border-green-400 px-1 py-0 items-center flex;
}

.tag-plan__disable {
  @apply border text-red-danger border-red-danger px-1 py-0 items-center flex;
}

.tag-plan__next {
  @apply border text-gray-caption border-gray-caption px-1 py-0 items-center flex;
}
</style>
<i18n>
{
  "en": {
    "my_plan": "My Plan",
    "use":  "In use",
    "not_use": "Not in use",
    "cancel_use":"Canceled",
    "remaining": "Remaining Period",
    "valid_until": "valid until",
    "diff_date": "{n} day | {n} days",
    "next_bill": "Next billing date",
    "change_plan": "Change Plan",
    "not_found": "Payment Not Found",
    "not_found_description": "You have not selected a payment plan yet. Go to “Select Plan”<br /> to proceed.",
    "select_plan": "Select Plan",
    "cancel_plan": "Cancel Plan",
    "next_plan": "Next plan",
    "start_end_at": "start/end date"
  },
  "th": {
    "my_plan": "แผนของเวิร์คสเปซในปัจจุบัน",
    "use":  "กำลังใช้งาน",
    "not_use": "ไม่ได้ใช้งาน",
    "cancel_use":"ยกเลิกการต่ออายุแล้ว",
    "remaining": "ระยะเวลาคงเหลือ",
    "valid_until": "ใช้ได้ถึง",
    "diff_date": "{n} วัน",
    "next_bill": "เก็บบิลครั้งต่อไป",
    "change_plan": "เปลี่ยนแผน",
    "not_found": "ไม่พบแผนการชำระเงิน",
    "not_found_description": "คุณไม่ได้เลือกแผนชำระเงินไว้ สามารถกดปุ่ม “เลือกแผน”<br /> เพื่อดำเนินการสมัครเวิร์คสเปซ",
    "select_plan": "เลือกแผน",
    "cancel_plan": "ยกเลิกการสมัคร",
    "next_plan": "แผนถัดไป",
    "start_end_at": "วันเริ่มต้น / สิ้นสุด"
  }
}
</i18n>






















































































import Vue, { PropType } from 'vue';
import ModalSubscriptionConfirm from '@/components/modal/modal-subscription-confirm.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ModalSubscriptionConfirm
  },
  data() {
    return {
      opened: false,
      loading: false,
      rating: 0,
      comeBackTosub: true
    };
  },
  computed: {
    ...mapGetters('subscription', ['getPlanCancel'])
  },
  methods: {
    async onSumbit() {
      this.loading = true;

      this.loading = false;
      this.$modal.hide('alert-freetrial');
    },
    async cancelSubscription() {
      const feedback = {
        subscriptionOrderID: this.getPlanCancel.id,
        usabilityRating: this.rating,
        willComeBackToSub: this.comeBackTosub
      };
      await this.$store.dispatch('subscription/cancelSubscription', feedback);
      this.closeModal();

      this.$modal.show(
        ModalSubscriptionConfirm,
        {
          title: this.$t('modal_cancel.title'),
          description: this.$t('modal_cancel.description'),
          animation:
            'https://assets3.lottiefiles.com/packages/lf20_uemoq57r.json'
        },
        {
          width: 400
        }
      );
    },
    closeModal() {
      this.$modal.hide('cancel-plan');
      this.rating = 0;
    }
  }
});

<template>
  <div>
    <p class="text-2xl mb-4">{{ $t('subscription.workspace_management') }}</p>
    <p class="text-lg mb-2">{{ $t('payment') }}</p>
    <p class="text-sm text-gray-caption">
      {{ $t('description') }}
    </p>
    <div>
      <div class="grid grid-cols-2 gap-4 mb-4 mt-3">
        <payment-current-plan />
        <payment-all-card />
      </div>
      <payment-history-bill />
    </div>
    <modal
      ref="subscription-confirm"
      name="subscription-confirm"
      :width="360"
      height="auto"
      scrollable
    >
      <modalSubscriptionConfirm
        ref="subscription-confirm"
        @close="$modal.hide('subscription-confirm')"
      />
    </modal>
  </div>
</template>
<script>
import paymentAllCard from '@/components/payment/payment-all-card';
import paymentHistoryBill from '@/components/payment/payment-history-bill';
import paymentCurrentPlan from '@/components/payment/payment-current-plan';
import modalSubscriptionConfirm from '@/components/modal/modal-subscription-confirm';

export default {
  components: {
    paymentAllCard,
    paymentHistoryBill,
    paymentCurrentPlan,
    modalSubscriptionConfirm
  }
};
</script>
<i18n>
{
  "en": {
    "title": "Workspace Management",
    "payment": "Payment",
    "description": "Add a credit or debit card to make your purchase."
  },
  "th": {
    "title": "ระบบจัดการเวิร์คสเปซ",
    "payment": "การชำระเงิน",
    "description": "เพิ่มการรับรองรูปแบบการชำระเงินด้วยบัตรเดบิตและเครดิต"
  }
}
</i18n>
